.icn {
  @apply flex;
  @apply content-center;
  @apply items-center;
}

.icn-stack:before {
  @apply px-3;
  @apply text-xl;
  @apply content-center;
  @apply font-icomoon;
  @apply text-th-primary;

  content: '\e93d' !important;
}
