@font-face {
  font-family: 'ProximaNova';
  src: url('/assets/fonts/ProximaNova-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('/assets/fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('/assets/fonts/ProximaNova-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/assets/fonts/Graphik-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

body {
  @apply font-body;
}

h1 {
  @apply font-bold text-lg md:text-3xl lg:text-4xl;
}

h2 {
  @apply text-xl;
}

h3 {
  @apply text-lg;
}
