.btn,
.button {
  @apply bg-th-primary border-2 border-th-primary disabled:opacity-50 text-white;

  &-link {
    @apply text-th-primary font-bold m-2 hover:text-blue-700 bg-none border-none;
  }

  &-block {
    @apply block;
  }

  &-lg {
    @apply p-4;
  }
  &-sm {
    @apply p-2;
  }

  &-main {
    @apply text-white font-bold w-full;
  }
}
