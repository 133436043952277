.badge {
  @apply inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium;
}

.badge-rounded {
  @apply rounded;
}
.badge-lg {
  @apply px-3 py-0.5 text-sm;
}
