@use 'libs/pa-ui/scss/styles.scss';
@use 'libs/pa-ui/scss/themes/default.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

.form-control:focus {
  border-color: currentColor !important;
  box-shadow: inset 0 1px 1px currentColor !important;
}

.form-control {
  color: currentColor !important;
}

.input-datepicker:focus {
  border-color: currentColor !important;
  box-shadow: inset 0 1px 1px currentColor !important;
}

.input-datepicker {
  color: currentColor !important;
}

div .has-float-label input {
  font-weight: var(--themable-font-weight) !important;
}

.animate__animated.animate__slideInRight {
  --animate-duration: 0.2s;
}

.animate__animated.animate__slideOutRight {
  --animate-duration: 0.2s;
}

.fixed-button-matdialog .mat-mdc-dialog-container {
  padding: 16px;
}
