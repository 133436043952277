@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon.eot');
  src: url('/assets/fonts/icomoon.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/icomoon.svg#IcoMoon') format('svg'),
    url('/assets/fonts/icomoon.woff') format('woff'),
    url('/assets/fonts/icomoon.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-muted {
  color: #e7e7e7;
}

.icon-pin:before {
  content: '\e943';
}

.icon-long-arrow-next:before {
  content: '\e942';
}

.icon-minus-outline:before {
  content: '\e941';
}

.icon-plus-outline:before {
  content: '\e940';
}

.icon-activity:before {
  content: '\e93f';
}

.icon-carret-circle-full:before {
  content: '\e93e';
}

.icon-Group:before {
  content: '\e93d';
}

.icon-msg-left:before {
  content: '\e93c';
}

.icon-Estimate:before {
  content: '\e93a';
}

.icon-encounter:before {
  content: '\e939';
}

.icon-Payment:before {
  content: '\e93b';
}

.icon-carret-circle:before {
  content: '\e938';
}

.icon-unlink:before {
  content: '\e937';
}

.icon-alert2:before {
  content: '\e936';
}

.icon-facebook:before {
  content: '\e931';
}

.icon-instagram:before {
  content: '\e932';
}

.icon-messenger:before {
  content: '\e933';
}

.icon-twitter:before {
  content: '\e934';
}

.icon-client:before {
  content: '\e92f';
}

.icon-location:before {
  content: '\e930';
}

.icon-logout:before {
  content: '\e935';
}

.icon-edit1:before {
  content: '\e92d';
}

.icon-save:before {
  content: '\e92b';
}

.icon-admin1:before {
  content: '\e92a';
}

.icon-admin:before {
  content: '\e929';
}

.icon-edit:before {
  content: '\e928';
}

.icon-close-circle:before {
  content: '\e927';
}

.icon-clear:before {
  content: '\e926';
}

.icon-download:before {
  content: '\e925';
}

.icon-plus1:before {
  content: '\e924';
}

.icon-revert:before {
  content: '\e922';
}

.icon-solid2:before {
  content: '\e921';
}

.icon-icn-users:before {
  content: '\e923';
}

.icon-long-arrow-back:before {
  content: '\e920';
}

.icon-payment-circle:before {
  content: '\e91f';
}

.icon-Star:before {
  content: '\e91e';
}

.icon-back-arrow:before {
  content: '\e91d';
}

.icon-menu:before {
  content: '\e91c';
}

.icon-payment:before {
  content: '\e917';
}

.icon-reports:before {
  content: '\e918';
}

.icon-settings:before {
  content: '\e919';
}

.icon-search:before {
  content: '\e91a';
}

.icon-phone-filled:before {
  content: '\e916';
}

.icon-minus:before {
  content: '\e915';
}

.icon-up:before {
  content: '\e914';
}

.icon-mail:before {
  content: '\e912';
}

.icon-print:before {
  content: '\e913';
}

.icon-txt:before {
  content: '\e911';
}

.icon-email:before {
  content: '\e902';
}

.icon-faq:before {
  content: '\e903';
}

.icon-phone:before {
  content: '\e904';
}

.icon-outline:before {
  content: '\e905';
}

.icon-solid:before {
  content: '\e906';
}

.icon-drop:before {
  content: '\e907';
}

.icon-link:before {
  content: '\e908';
}

.icon-help:before {
  content: '\e909';
}

.icon-plus:before {
  content: '\e90a';
}

.icon-alert:before {
  content: '\e90b';
}

.icon-calendar:before {
  content: '\e90c';
}

.icon-check:before {
  content: '\e90d';
}

.icon-close:before {
  content: '\e90e';
}

.icon-document:before {
  content: '\e90f';
}

.icon-notificationBadge:before {
  content: '\e910';
}

.icon-back:before {
  content: '\e900';
}

.icon-empty:before {
  content: '\e901';
}

.icon-worklist:before {
  content: '\e91b';
}

.icon-at-symbol:before {
  content: '\0040';
}

.icon-dollar:before {
  content: '\FF04';
}
