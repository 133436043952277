.card {
  @apply shadow-th-card p-12 bg-th-background;
}

.message {
  @apply bg-th-primary;
  @apply text-white;
  @apply p-8;
  @apply relative;
  @apply text-lg md:text-2xl;

  &:before {
    @apply font-icomoon;
    @apply text-th-primary;
    @apply absolute;
    content: '\e93c';
    bottom: -20px;
    left: -17px;
    font-size: 2.5rem;
  }
}
