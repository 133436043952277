pa-field-set > fieldset > div {
  @apply relative flex justify-center;
  @apply w-full h-16;
  @apply relative flex items-center justify-center;
  @apply border-2 border-gray-500 rounded-sm;
  @apply focus-within:border-th-primary;
}

pa-field-set > fieldset > div.isValid,
pa-field-set > fieldset > div.ng-valid {
  @apply border-2 border-gray-500 focus-within:border-th-primary;
  @apply focus-within:ring-indigo-500 focus-within:border-indigo-500;
}

pa-field-set > fieldset > div.isInvalid.isTouched,
pa-field-set > fieldset > div.ng-invalid.ng-touched {
  @apply border-2 border-red-500 focus-within:border-th-primary;
  @apply text-gray-700 leading-tight;
  @apply focus-within:ring-red-500 focus-within:border-red-500;
}

pa-field-set ~ input:not([type]),
pa-field-set ~ input[type='text'],
pa-field-set ~ input[type='date'],
pa-field-set ~ input[type='email'],
pa-field-set ~ input[type='password'],
pa-field-set ~ button {
  @apply outline-none border-none;
  @apply w-full;
  @apply h-full;
  @apply border-none focus:ring-0;
}

pa-field-set ~ label[for] {
  @apply text-th-secondary;
  @apply opacity-75;
  @apply pointer-events-none;
  @apply absolute top-0;
  @apply left-3;
  @apply p-4 text-base md:text-lg duration-100 origin-0 truncate;
  @apply flex;
}

pa-field-set ~ input:focus ~ label[for],
pa-field-set ~ input:not(:placeholder-shown) ~ label[for] {
  @apply transform scale-75 -translate-y-6  ml-3 px-2 py-2;
  @apply z-0;
  @apply text-th-secondary bg-white;
  @apply opacity-100;
  @apply items-center;
}
pa-field-set ~ input::placeholder {
  @apply text-transparent;
}
