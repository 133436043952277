:root {
  --primary: theme('colors.blue.500');
  --secondary: theme('colors.blue.800');
  --tertiary: theme('colors.blue.100');
  --text-primary: theme('colors.black');

  --hover-primary: theme('colors.blue.700');
  --hover-secondary: theme('colors.blue.600');

  --focus-primary: theme('colors.sky.200');
  --focus-secondary: theme('colors.sky.100');

  --login-primary: theme('colors.blue.500');
  --login-secondary: theme('colors.blue.200');

  --login-hover-primary: theme('colors.blue.700');
  --login-hover-secondary: theme('colors.blue.400');

  --sidenav-primary: theme('colors.blue.200');
  --sidenav-secondary: theme('colors.blue.50');

  --toolbar-progress-primary: theme('colors.blue.500');
  --toolbar-progress-secondary: theme('colors.blue.200');

  --toolbar-primary: theme('colors.blue.500');
  --toolbar-secondary: theme('colors.blue.400');

  --toolbar-hover-primary: theme('colors.blue.700');
  --toolbar-hover-secondary: theme('colors.blue.600');

  --footer-toolbar-primary: theme('colors.blue.500');
  --footer-toolbar-secondary: theme('colors.blue.400');

  --footer-toolbar-hover-primary: theme('colors.blue.700');
  --footer-toolbar-hover-secondary: theme('colors.blue.600');

  --ocr-toolbar-primary: theme('colors.blue.500');
  --ocr-toolbar-hover-primary: theme('colors.blue.700');

  --ocr-toolbar-secondary: theme('colors.blue.500');
  --ocr-toolbar-hover-secondary: theme('colors.blue.700');

  --background: theme('colors.white');
  --navbar-background: theme('colors.white');
  --sidenav-background: theme('colors.white');

  --spinner-primary: theme('colors.blue.900');
  --spinner-secondary: theme('colors.blue.900');

  --accent: theme('colors.gray.400');
  --accent-primary: theme('colors.blue.50');

  --line: theme('colors.gray.200');
  --card-shadow: theme('boxShadow.card-lg');

  --icon-primary: theme('colors.blue.500');
  --toolbar-no-errors: theme('colors.green.500');
  --toolbar-no-errors-hover: theme('colors.green.600');
}
